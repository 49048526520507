import { useEffect, useState } from 'react'
import { Steps, Divider, Checkbox, Result, Form, Spin, Grid } from 'antd'
import { FormikErrors, FormikTouched, useFormik } from 'formik'
import * as yup from 'yup'

import {
  UserOutlined,
  MailOutlined,
  ArrowLeftOutlined,
  PhoneOutlined,
  LockOutlined,
} from '@ant-design/icons'

import { useListCategories, obtainLegal, useRegister } from 'src/api/graphql'

import { Platforms } from 'src/interfaces/types/app'

import { QButton, QLoading, QNotify } from 'src/shared/components'

import { validateRUC } from './helpers/validations'
import constants from './helpers/constants'

import {
  PageStyle,
  StepsStyle,
  ContentStyle,
  InputStyle,
  CardStyle,
  SelectStyle,
  PasswordStyle,
  CardResultStyle,
  FinishStyle,
} from './styles'

import Colors from '../../shared/theme/colors'

const { useBreakpoint } = Grid

interface IStepProps {
  step: number
  onBefore?: () => void
  onNext?: () => void
  fillUserData?: (userData: Partial<UserData & CompanyData & PasswordData>) => void
  userData?: UserData & CompanyData & PasswordData
}

interface UserData {
  name: string
  lastname: string
  email: string
}
interface CompanyData {
  isBuyer: boolean
  isProvider: boolean
  ruc: string
  company: string
  address: string
  categories: string[]
  cellphone: string
}
interface PasswordData {
  password: string
  rePassword: string
  isTerms: boolean
  isMkt?: boolean
}

const required = {
  general: 'Este campo es requerido',
  matchNumbers: '¡Este campo debe contener valores numéricos!',
  matchMin: '¡Este campo debe contener al menos 11 dígitos!',
  matchMinPhone: '¡El número de teléfono debe tener al menos 9 dígitos!',
  matchEmail: '¡El email no es válido!',
}

const validationStep1 = yup.object().shape({
  name: yup.string().required(required.general),
  lastname: yup.string().required(required.general),
  email: yup.string().email(required.matchEmail).required(required.general),
})

const validationStep2 = yup.object().shape({
  ruc: yup
    .string()
    // .matches(/^[0-9]{11}$/, 'El número de RUC no es válido.')
    .required(required.general)
    .matches(/^[0-9]+$/, required.matchNumbers)
    .min(11, required.matchMin),
  categories: yup.array().when('isBuyer', {
    is: true,
    then: yup.array().nullable(),
    otherwise: yup.array().min(1, 'Este campo es requerido.').required(required.general),
  }),
  cellphone: yup
    .string()
    // .matches(/^[0-9]{9}$/, 'El número de teléfono no es válido.')
    .required(required.general)
    .matches(/^[0-9]+$/, required.matchNumbers)
    .min(9, required.matchMinPhone),
})

const validationStep3 = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?/@#\$%\^&\*()\-\+\:\;])(?=.{8,})/,
      'La contraseña debe tener al menos 8 caracteres y contener una letra mayúscula, una minúscula, un número y un símbolo - / : ; ()& @?! % *'
    )
    .required(required.general),
  rePassword: yup
    .string()
    .required(required.general)
    .oneOf([yup.ref('password'), null], 'Las contraseñas deben ser iguales'),
  isTerms: yup.bool().oneOf([true], 'Este campo es requerido').required(),
})

const Register = () => {
  const [step, setStep] = useState<number>(0)
  const [userData, setUserData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const { sm } = useBreakpoint()

  useEffect(() => {
    setLoading(false)
  }, [])

  const left = (
    <StepsStyle>
      <label className="title">Crear cuenta</label>
      <Steps
        progressDot={!sm}
        size={!sm ? 'small' : 'default'}
        direction={'vertical'}
        current={step}
        items={[{ title: 'Datos Personales' }, { title: 'Razón Social' }, { title: 'Contraseña' }]}
      />
    </StepsStyle>
  )

  const page = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 20,
        }}
      >
        <a style={{ color: '#1890ff' }} href={'/'}>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          <span>Salir a inicio</span>
        </a>
        <img width={140} height={41.6} alt="Equip logo" src="/logos/logo.svg" />
      </div>
      <PageStyle>
        {left}

        <ContentStyle>
          {step === 0 && (
            <Step1
              step={step}
              onNext={() => setStep(1)}
              fillUserData={(formData) => setUserData({ ...userData, ...formData })}
            />
          )}
          {step === 1 && (
            <Step2
              step={step}
              onNext={() => setStep(2)}
              fillUserData={(formData) => setUserData({ ...userData, ...formData })}
            />
          )}
          {step === 2 && (
            <Step3
              step={step}
              onBefore={() => setStep(1)}
              onNext={() => setStep(3)}
              fillUserData={(formData) => setUserData({ ...userData, ...formData })}
              userData={userData}
            />
          )}
        </ContentStyle>
      </PageStyle>
    </>
  )

  return loading ? (
    <QLoading loading={true}>
      <></>
    </QLoading>
  ) : (
    <>{step === 3 ? <Step4 /> : page}</>
  )
}

const hasToShowError = <T,>(
  formik: {
    errors: FormikErrors<T>
    touched: FormikTouched<T>
  },
  key: keyof T
) => {
  return formik.errors[key] && formik.touched[key]
}

const Step1 = (props: IStepProps) => {
  const formik = useFormik<UserData>({
    initialValues: { name: '', lastname: '', email: '' },
    validationSchema: validationStep1,
    onSubmit: (values) => {
      props.fillUserData?.(values)
      props.onNext && props.onNext()
    },
  })

  return (
    <>
      <Form layout="vertical" onFinish={(values) => formik.handleSubmit(values)}>
        <label className="form-title">Datos personales</label>
        <Form.Item
          validateStatus={hasToShowError(formik, 'name') ? 'error' : undefined}
          help={hasToShowError(formik, 'name') && formik.errors.name}
        >
          <InputStyle
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            placeholder="Nombre"
            prefix={<UserOutlined style={{ color: Colors.blue }} />}
          />
        </Form.Item>
        <Form.Item
          validateStatus={hasToShowError(formik, 'lastname') ? 'error' : undefined}
          help={hasToShowError(formik, 'lastname') && formik.errors.lastname}
        >
          <InputStyle
            placeholder="Apellido"
            prefix={<UserOutlined style={{ color: Colors.blue }} />}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            name="lastname"
          />
        </Form.Item>
        <Form.Item
          validateStatus={hasToShowError(formik, 'email') ? 'error' : undefined}
          help={hasToShowError(formik, 'email') && formik.errors.email}
        >
          <InputStyle
            placeholder="Correo electrónico"
            prefix={<MailOutlined style={{ color: Colors.blue }} />}
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
          />
        </Form.Item>
        <div className="actions">
          <QButton
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!formik.dirty || !formik.isValid}
          >
            Siguiente
          </QButton>
        </div>
      </Form>
    </>
  )
}

const Step2 = (props: IStepProps) => {
  const [type, setType] = useState<keyof typeof constants.userType>('buyer')
  const [next, setNext] = useState<boolean>(false)
  const [ruc, setRUC] = useState('')

  const [loadInfoLegal, infoLegal] = obtainLegal({ input: { ruc } })
  const { data, loading } = useListCategories({ page: 1, perPage: 500 }, {})

  const formik = useFormik<CompanyData>({
    initialValues: {
      ruc: '',
      company: '',
      cellphone: '',
      address: '',
      isBuyer: false,
      isProvider: false,
      categories: [],
    },
    validationSchema: validationStep2,
    onSubmit: (values) => {
      props.fillUserData?.({ ...values })
      props.onNext?.()
    },
  })

  const handleRUC = ({ target: { value: ruc } }: any) => {
    if (validateRUC(ruc)) {
      setRUC(ruc)
      loadInfoLegal()
    }
  }

  const handleTap = (type: keyof typeof constants.userType) => {
    setType(type)
    formik.setFieldValue('isBuyer', type === 'hybrid' || type === 'buyer')
    formik.setFieldValue('isProvider', type === 'hybrid' || type === 'provider')
    setNext(true)
  }

  const cards = (
    <>
      <h5>¿Qué tipo de usuario eres?</h5>
      <div className="cards">
        <div>
          <CardStyle onClick={() => handleTap('buyer')}>
            <div className="grid">
              <img src="/icons/buyer.svg" alt="Buyer" height={75} width={75} />
              <span>Comprador</span>
            </div>
          </CardStyle>
        </div>
        <div>
          <CardStyle onClick={() => handleTap('provider')}>
            <div className="grid">
              <img src="/icons/provider.svg" alt="Provider" height={75} width={75} />
              <span>Proveedor</span>
            </div>
          </CardStyle>
        </div>
        <div>
          <CardStyle onClick={() => handleTap('hybrid')}>
            <div className="grid">
              <img src="/icons/hybrid.svg" alt="Both" height={75} width={75} />
              <span>Ambos</span>
            </div>
          </CardStyle>
        </div>
      </div>
    </>
  )

  const form = (
    <Form layout="vertical" onFinish={(values) => formik.handleSubmit(values)}>
      <div className="form-header">
        <span className="subtitle">{constants.userType[type]}</span>
        <Divider type="vertical" />
        <span className="subtitle-link" onClick={() => setNext(false)}>
          Cambiar tipo de usuario
        </span>
      </div>

      <Form.Item
        validateStatus={hasToShowError(formik, 'ruc') ? 'error' : undefined}
        help={hasToShowError(formik, 'ruc') ? formik.errors.ruc : ''}
      >
        <InputStyle
          onChange={(e: any) => {
            formik.handleChange(e)
            handleRUC(e)
          }}
          onBlur={formik.handleBlur}
          name="ruc"
          placeholder="RUC de la empresa"
          prefix={<UserOutlined style={{ color: Colors.blue }} />}
          value={formik.values.ruc}
        />
      </Form.Item>
      {infoLegal?.businessName && (
        <p style={{ margin: '0px 0px 10px' }}>{infoLegal?.businessName}</p>
      )}
      {['provider', 'hybrid'].includes(type) && (
        <Form.Item
          validateStatus={hasToShowError(formik, 'categories') ? 'error' : undefined}
          help={hasToShowError(formik, 'categories') && formik.errors.categories}
        >
          <SelectStyle
            onChange={(cats: any) => {
              formik.setFieldValue('categories', cats)
            }}
            defaultValue={formik.values.categories}
            mode="tags"
            placeholder="Categorías"
            loading={loading}
          >
            {data?.listCategories?.categories?.map((cat: any) => (
              <SelectStyle.Option key={cat.id} value={cat.name}>
                {cat.name}
              </SelectStyle.Option>
            ))}
          </SelectStyle>
        </Form.Item>
      )}
      <Form.Item
        validateStatus={formik.touched.cellphone && formik.errors.cellphone ? 'error' : undefined}
        help={formik.touched.cellphone && formik.errors.cellphone ? formik.errors.cellphone : ''}
      >
        <InputStyle
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="cellphone"
          value={formik.values.cellphone}
          placeholder="Teléfono/Celular"
          prefix={<PhoneOutlined style={{ color: Colors.blue }} />}
        />
      </Form.Item>

      <div className="actions-between">
        <QButton onClick={() => setNext(false)}>
          <ArrowLeftOutlined />
        </QButton>
        <QButton
          type="primary"
          size="large"
          htmlType="submit"
          disabled={!formik.dirty || !formik.isValid}
        >
          Continuar
        </QButton>
      </div>
    </Form>
  )

  useEffect(() => {
    if (infoLegal) {
      formik.setFieldValue('company', infoLegal.businessName ?? '')
      formik.setFieldValue('address', infoLegal.businessLocation?.address ?? 'Sin Dirección')
    } else {
      formik.setFieldValue('company', '')
      formik.setFieldValue('address', 'Sin Dirección')
    }
  }, [infoLegal.businessName])

  return (
    <div className="step-2-container">
      <label className="form-title">Razón Social</label>

      {next ? form : cards}
    </div>
  )
}

const Step3 = (props: IStepProps) => {
  const [register, { loading }] = useRegister()

  const formik = useFormik<PasswordData>({
    initialValues: { isTerms: true, isMkt: false, password: '', rePassword: '' },
    validationSchema: validationStep3,
    onSubmit: (values) => handleRegister(values),
  })

  const handleRegister = (values: PasswordData) => {
    const input = {
      ...props.userData,
      password: values.password,
      isMkt: values.isMkt,
      isTerms: values.isTerms,
      documentType: '',
      documentNumber: '',
      status: 'registered',
      flow: 'normal',
      origin: 'landing',
    }
    const variables = { input }
    register({ context: { clientName: Platforms.accounts }, variables })
      .then(() => handleSuccess())
      .catch((err: any) => handleError(err))
  }

  const handleSuccess = () => props?.onNext?.()

  const handleError = (err: any) => {
    QNotify({ type: 'error', message: err.message })
  }

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        onFinish={(values) => formik.handleSubmit(values)}
        style={{ maxWidth: 633 }}
      >
        <label className="form-title">Contraseña</label>
        <Form.Item
          validateStatus={hasToShowError(formik, 'password') ? 'error' : undefined}
          help={hasToShowError(formik, 'password') && formik.errors.password}
        >
          <PasswordStyle
            placeholder="Contraseña"
            prefix={<LockOutlined style={{ color: Colors.blue }} />}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          validateStatus={hasToShowError(formik, 'password') ? 'error' : undefined}
          help={hasToShowError(formik, 'password') && formik.errors.password}
        >
          <PasswordStyle
            placeholder="Confirmar contraseña"
            prefix={<LockOutlined style={{ color: Colors.blue }} />}
            name="rePassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <div className="checkboxes">
          <Form.Item
            validateStatus={hasToShowError(formik, 'isTerms') ? 'error' : undefined}
            help={hasToShowError(formik, 'isTerms') && formik.errors.isTerms}
          >
            <Checkbox onChange={formik.handleChange} name="isTerms" checked={formik.values.isTerms}>
              Acepto los{' '}
              <a href="/policies/terms" target="_blank">
                Términos y Condiciones
              </a>{' '}
              y la{' '}
              <a href="/policies/privacy" target="_blank">
                Política de Privacidad y Tratamiento de Datos Personales
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item
            validateStatus={hasToShowError(formik, 'isMkt') ? 'error' : undefined}
            help={hasToShowError(formik, 'isMkt') && formik.errors.isMkt}
          >
            <Checkbox onChange={formik.handleChange} name="isMkt" checked={formik.values.isMkt}>
              Acepto el uso de mis datos personales para fines publicitarios
            </Checkbox>
          </Form.Item>
        </div>
        <div className="actions-between">
          <QButton onClick={props.onBefore}>
            <ArrowLeftOutlined />
          </QButton>
          <QButton
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!formik.dirty || !formik.isValid}
          >
            Completar registro
          </QButton>
        </div>
      </Form>
    </Spin>
  )
}

const Step4 = () => {
  return (
    <FinishStyle>
      <CardResultStyle>
        <Result
          status="success"
          title="Cuenta registrada"
          subTitle="Gracias por registrarte a EQUIP, antes de empezar verifica tu cuenta con el correo que te acabamos de enviar."
        />
      </CardResultStyle>
      <a href="/">
        <QButton>Salir a inicio</QButton>
      </a>
    </FinishStyle>
  )
}

export default Register
