import * as yup from 'yup'

const required = {
  general: 'Este campo es obligatorio',
  matchNumbers: '¡Este campo debe contener valores numéricos!',
  matchMin: '¡Este campo debe contener al menos 11 dígitos!',
  matchMinPhone: '¡El número de teléfono debe tener al menos 9 dígitos!',
  matchEmail: '¡Este campo debe contener un email válido!',
}

export const requirementSchema = yup.object().shape({
  fullname: yup.string().required(required.general),
  email: yup.string().email(required.matchEmail).required(required.general),
  // ruc: yup.string().length(11, 'RUC inválido').required('Este campo es requerido'),
  ruc: yup
    .string()
    .required(required.general)
    .matches(/^[0-9]+$/, required.matchNumbers)
    .min(11, required.matchMin),
  // cellphone: yup.string().length(9, 'RUC inválido').required('Este campo es requerido'),
  cellphone: yup
    .string()
    .required(required.general)
    .matches(/^[0-9]+$/, required.matchNumbers)
    .min(9, required.matchMinPhone),
  terms: yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones'),
})
