import { useNavigate } from 'react-router-dom'
import { Empty, Grid, InputNumber } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'

import { useCart } from 'src/shared/hooks'

import { Product } from 'src/interfaces/ecommerce'

import { ProductItem, QButton } from 'src/shared/components'

import config from '../../../config/app'

import { CardStyled, CartFooterStyled, CartStyled } from './styles'

const wsp_endpoint = `https://api.whatsapp.com`
const cellNumber = config.web.cellNumber?.replace(/([\+\ \&])/g, '')
const message = 'Hola, me gustaría solicitar una cotización rápida.'

const Cart = () => {
  const navigate = useNavigate()
  const { sm } = Grid.useBreakpoint()

  const { products, removeEntireProductFromCart, updProduct } = useCart()

  const handleRemove = (product: Product) => {
    removeEntireProductFromCart(product)
  }

  const handleQuantity = (value: number | null, product: Product) => {
    if (value && value >= 1) {
      updProduct({ ...product, qty: value ?? 0 })
    } else {
      updProduct({ ...product, qty: 1 })
    }
  }

  const handleWhatsapp = () => {
    window.open(`${wsp_endpoint}/send?phone=${cellNumber}&text=${message}`, '_blank')
  }

  const handleNext = () => {
    navigate('/checkout')
  }

  return (
    <CartStyled>
      <h2>Carrito de Compra</h2>
      <CardStyled>
        {products.map((product: Product) => (
          <ProductItem
            key={product.id}
            size={sm ? 'large' : undefined}
            product={{ ...product, qty: undefined }}
            footer={
              <CartFooterStyled>
                <div className="column">
                  {sm ? <label>Cantidad</label> : null}
                  <InputNumber
                    value={product.qty}
                    onChange={(value) => handleQuantity(value, product)}
                  />
                </div>
                <div className="column--end">
                  <QButton
                    icon={<MinusCircleOutlined />}
                    danger
                    type="text"
                    onClick={() => handleRemove(product)}
                  >
                    Eliminar
                  </QButton>
                </div>
              </CartFooterStyled>
            }
          />
        ))}

        {Boolean(products.length) ? (
          <div className="cart-footer">
            <QButton equip="whatsapp" size="large" onClick={handleWhatsapp}>
              Cotizar por Whatsapp
            </QButton>
            <QButton equip="default" size="large" onClick={handleNext}>
              Continuar en la Web
            </QButton>
          </div>
        ) : (
          <Empty
            style={{ padding: 50 }}
            description={
              <span>
                Volver a <a href="/home">Comprar</a>
              </span>
            }
          />
        )}
      </CardStyled>
    </CartStyled>
  )
}

export default Cart
